import React from 'react';
import { useStyles } from '../styles';
import RewardsTable from './components/RewardsTable';
import { LabelImportantOutlined } from '@material-ui/icons';

const GamificationRewards = () => {

    const classes = useStyles();
    
    return (
        <div className={classes.rewardAdminContainer}>
            <div className={classes.rewardAdminTitle}>
                <LabelImportantOutlined />
                <h5>Gestão de Recompensas</h5>
            </div>
            <RewardsTable />
        </div>
    )
}

export default GamificationRewards;