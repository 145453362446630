import theme from '../theme';

export const statusUser = {
  1: {
    style: { color: theme.palette.success.main },
    title: 'label:commons.label_status_active',
  },
  2: {
    style: { color: theme.palette.error.main },
    title: 'label:commons.label_status_inactive',
  },
  3: {
    style: { color: theme.palette.default.main },
    title: 'label:commons.label_status_test',
  },
  4: {
    style: { color: theme.palette.default.main },
    title: 'label:commons.label_status_blocked',
  },
};

export const statusProgress = {
  1: {
    style: { color: theme.palette.success.main },
    title: 'label:commons.label_status_progress_notstarted',
  },
  2: {
    style: { color: theme.palette.error.main },
    title: 'label:commons.label_status_progress_inprogress',
  },
  3: {
    style: { color: theme.palette.default.main },
    title: 'label:commons.label_status_progress_approve',
  },
  4: {
    style: { color: theme.palette.default.main },
    title: 'label:commons.label_status_progress_disapproved',
  },
  5: {
    style: { color: theme.palette.default.main },
    title: 'label:commons.label_status_year_all',
  },
};

export const statusPeriodFinished = {
  1: {
    style: { color: theme.palette.success.main },
    title: 'label:commons.label_status_year_one',
  },
  2: {
    style: { color: theme.palette.error.main },
    title: 'label:commons.label_status_year_two',
  },
  3: {
    style: { color: theme.palette.default.main },
    title: 'label:commons.label_status_year_three',
  },
  4: {
    style: { color: theme.palette.default.main },
    title: 'label:commons.label_status_year_all',
  },
  5: {
    style: { color: theme.palette.default.main },
    title: 'label:commons.label_status_year_four',
  },
  6: {
    style: { color: theme.palette.default.main },
    title: 'label:commons.label_status_year_five',
  },
};

export const statusPeriodCreated = {
  1: {
    style: { color: theme.palette.success.main },
    title: 'label:commons.label_status_year_one',
  },
  2: {
    style: { color: theme.palette.error.main },
    title: 'label:commons.label_status_year_two',
  },
  3: {
    style: { color: theme.palette.default.main },
    title: 'label:commons.label_status_year_three',
  },
  4: {
    style: { color: theme.palette.default.main },
    title: 'label:commons.label_status_year_all',
  },
};

export const statusCreatedOrFinished = {
  1: {
    style: { color: theme.palette.success.main },
    title: 'label:commons.label_status_created',
  },
  2: {
    style: { color: theme.palette.error.main },
    title: 'label:commons.label_status_finished',
  },
};


export const statusCompany = {
  1: {
    style: { color: theme.palette.success.main },
    title: 'label:commons.label_status_active',
  },
  2: {
    style: { color: theme.palette.error.main },
    title: 'label:commons.label_status_inactive',
  },
  3: {
    style: { color: theme.palette.default.main },
    title: 'label:commons.label_status_test',
  },
};

export const statusUserSolicitation = {
  1: {
    style: { color: theme.palette.warning.main },
    title: 'label:commons.label_status_pending',
  },
  2: {
    style: { color: theme.palette.success.main },
    title: 'label:commons.label_status_accepted',
  },
  3: {
    style: { color: theme.palette.error.main },
    title: 'label:commons.label_status_refuse',
  },
};

export const statusCategories = {
  true: {
    style: { color: theme.palette.success.main },
    title: 'label:commons.label_status_active',
  },
  false: {
    style: { color: theme.palette.error.main },
    title: 'label:commons.label_status_inactive',
  },
};

export const statusContracts = {
  true: {
    style: { color: theme.palette.success.main },
    title: 'label:commons.label_status_active',
    icon: 'edit',
  },
  false: {
    style: { color: theme.palette.error.main },
    title: 'label:commons.label_status_inactive',
    icon: 'visibility',
  },
};

export const statusUserContracts = {
  true: {
    style: { color: theme.palette.success.main },
    title: 'label:commons.label_status_accepted',
    icon: 'edit',
  },
  false: {
    style: { color: theme.palette.error.main },
    title: 'label:commons.label_status_pending',
    icon: 'visibility',
  },
};

export const statusYesNo = {
  true: {
    style: { color: theme.palette.success.main },
    title: 'label:commons.label_yes',
  },
  false: {
    style: { color: theme.palette.error.main },
    title: 'label:commons.label_no',
  },
};

export const statusGroups = {
  1: {
    style: { color: theme.palette.primary.main },
    title: 'label:Group.label_type_admin',
  },
  2: {
    style: { color: theme.palette.secondary.main },
    title: 'label:Group.label_type_student',
  },
  3: {
    style: { color: theme.palette.info.main },
    title: 'label:Group.label_type_teacher',
  },
};
export const statusCourses = {
  1: {
    style: { color: theme.palette.primary.main },
    title: 'label:Course.label_draft_status',
  },
  2: {
    style: { color: theme.palette.secondary.main },
    title: 'label:Course.label_published_status',
  },
  3: {
    style: { color: theme.palette.info.main },
    title: 'label:Course.label_unpublished_status',
  },
};

export const statusAttendance = {
  1: {
    style: { color: theme.palette.warning.main },
    title: 'label:commons.label_status_pending',
  },
  2: {
    style: { color: theme.palette.success.main },
    title: 'label:Attendance.label_status_read',
  },
};
