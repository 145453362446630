import React, { useEffect, useState } from 'react';
import GamificationData from 'services/dataService/GamificationData';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    container: {
        padding: '20px 5px',
        display: 'flex',
        alignItems: 'center',
    },
    table: {
        width: '100%',
        maxWidth: '800px',
        borderCollapse: 'collapse',
        borderRadius: '8px',
        overflow: 'hidden',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    },
    thead: {
        backgroundColor: '#176AE6',
        color: '#fff',
    },
    th: {
        padding: '12px 20px',
        textAlign: 'center',
        fontWeight: 'bold',
    },
    tbody: {
        '& tr:nth-child(even)': {
            backgroundColor: '#f9f9f9',
        },
        '& tr:hover': {
            backgroundColor: '#f1f1f1',
        },
    },
    td: {
        padding: '12px 20px',
        textAlign: 'center',
        fontSize: '16px',
        color: '#333',
    },
    rank: {
        fontWeight: 'bold',
        color: '#333',
    },
    score: {
        fontWeight: 'bold',
        color: '#176AE6',
    },
});

const ReportsTable = () => {
    const classes = useStyles();
    const [userRewards, setUserRewards] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        try {
            const allUserRewards = await GamificationData.getUserRewards();
            console.log('allUserRewards >', allUserRewards);
            setUserRewards(allUserRewards.data); // Supondo que o backend retorna `data`
            setLoading(false);
        } catch (error) {
            console.error('Erro ao buscar dados:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (loading) {
        return <p>Carregando...</p>;
    }

    return (
        <div className={classes.container}>
            <table className={classes.table}>
                <thead className={classes.thead}>
                    <tr>
                        <th className={classes.th}>Nome</th>
                        <th className={classes.th}>E-mail</th>
                        <th className={classes.th}>CPF</th>
                        <th className={classes.th}>Resgate</th>
                        <th className={classes.th}>Quantidade</th>
                        <th className={classes.th}>Custo</th>
                        <th className={classes.th}>Data Resgate</th>
                        <th className={classes.th}>Status</th>
                    </tr>
                </thead>
                <tbody className={classes.tbody}>
                    {userRewards.map((reward) => (
                        <tr key={reward.id}>
                            <td className={classes.td}>{reward.user?.name   || 'Usuário não encontrado'}</td>
                            <td className={classes.td}>{reward.user?.email  || ' '}</td>
                            <td className={classes.td}>{reward.user?.cpf    || ' '}</td>
                            <td className={classes.td}>{reward.reward?.name || 'Recompensa não encontrada'}</td>
                            <td className={classes.td}>{reward?.quantity    || '-'}</td>
                            <td className={classes.td}>{reward.total_cost}</td>
                            <td className={classes.td}>{reward?.data_rescue}</td>
                            <td className={classes.td}>{reward?.status_rescue}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ReportsTable;

