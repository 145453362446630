import React, { useState, useEffect } from 'react';
import GamificationData from 'services/dataService/GamificationData';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Menu, TextField, Typography } from '@material-ui/core';

const useStyles = makeStyles({
    container: {
        padding: '20px 5px',
        display: 'flex',
        alignItems: 'center',
    },
    table: {
        width: '100%',
        maxWidth: '800px',
        borderCollapse: 'collapse',
        borderRadius: '8px',
        overflow: 'hidden',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    },
    thead: {
        backgroundColor: '#176AE6',
        color: '#fff',
    },
    th: {
        padding: '12px 20px',
        textAlign: 'center',
        fontWeight: 'bold',
    },
    tbody: {
        '& tr:nth-child(even)': {
            backgroundColor: '#f9f9f9',
        },
        '& tr:hover': {
            backgroundColor: '#f1f1f1',
        },
    },
    td: {
        padding: '12px 20px',
        textAlign: 'center',
        fontSize: '16px',
        color: '#333',
    },
    rank: {
        fontWeight: 'bold',
        color: '#333',
    },
    score: {
        fontWeight: 'bold',
        color: '#176AE6',
    },
});

export default () => {
    const classes = useStyles();
    const [usersData, setUsersData] = useState([]);

    async function getData() {
        const rankingResponse = await GamificationData.getRankingUserCoins();
        setUsersData(rankingResponse.data);
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <Typography variant='h4'>Em breve...</Typography>
        // <div className={classes.container}>
        //     <table className={classes.table}>
        //         <thead className={classes.thead}>
        //             <tr>
        //                 <th className={classes.th}>#</th>
        //                 <th className={classes.th}>Nome</th>
        //                 <th className={classes.th}>Email</th>
        //                 <th className={classes.th}>Pontuação</th>
        //             </tr>
        //         </thead>
        //         <tbody className={classes.tbody}>
        //             {usersData.map((user, index) => (
        //                 <tr key={user.id}>
        //                     <td className={`${classes.td} ${classes.rank}`}>{index + 1}</td>
        //                     <td className={classes.td}>{user.name}</td>
        //                     <td className={classes.td}>{user.email}</td>
        //                     <td className={`${classes.td} ${classes.score}`}>{user.totalcoins}</td>
        //                 </tr>
        //             ))}
        //         </tbody>
        //     </table>
        // </div>
    );
};
