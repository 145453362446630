import React from 'react';
import { useStyles } from '../styles';
import { GraphicEqOutlined } from '@material-ui/icons';
import Ranking from './components/Ranking';

const GamificationRanking = () => {

    const classes = useStyles();

    return (
        <div className={classes.rankingTabContainer}>
            <div className={classes.rankingAdminTitle}>
                <GraphicEqOutlined />
                <h5>Ranking</h5>
            </div>
            <Ranking />
        </div>
    )
}

export default GamificationRanking;