export const months = [
  {
    id: '01',
    name: 'label:months.january',
  },
  {
    id: '02',
    name: 'label:months.february',
  },
  {
    id: '03',
    name: 'label:months.march',
  },
  {
    id: '04',
    name: 'label:months.april',
  },
  {
    id: '05',
    name: 'label:months.may',
  },
  {
    id: '06',
    name: 'label:months.june',
  },
  {
    id: '07',
    name: 'label:months.july',
  },
  {
    id: '08',
    name: 'label:months.august',
  },
  {
    id: '09',
    name: 'label:months.september',
  },
  {
    id: '10',
    name: 'label:months.october',
  },
  {
    id: '11',
    name: 'label:months.november',
  },
  {
    id: '12',
    name: 'label:months.december',
  },
];

export const years = [
  {
    id: '2021',
    name: 'label:years.label_status_year_one',
  },
  {
    id: '2022',
    name: 'label:years.label_status_year_two',
  },
  {
    id: '2023',
    name: 'label:years.label_status_year_three',
  },
  {
    id: '2024',
    name: 'label:years.label_status_year_four',
  },
  {
    id: '2025',
    name: 'label:years.label_status_year_five',
  },
];
