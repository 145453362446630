import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import GamificationData from 'services/dataService/GamificationData';
import { useStyles } from '../styles';
import { SettingsOutlined } from '@material-ui/icons';

const GamificationSettings = () => {

    const classes = useStyles();
    const [coinName, setCoinName] = useState();
    const [scoreCoin, setScoreCoin] = useState();
    const [feedbackMessage, setFeedbackMessage] = useState(null);

    const { id_company } = useSelector(
        (state) => state.auth
    );

    async function getGamification(id) {
        const gamificationData = await GamificationData.getGamificationByIdCompany({ "id_company": id_company });
        const { data } = gamificationData;
        data.map((gamification, index) => {
            setCoinName(gamification.coin_name);
            setScoreCoin(gamification.score);
        })
    }

    async function handleUpdateGamification() {
        const { status } = await GamificationData.updateGamification({ coin_name: coinName, score: scoreCoin, id_company: id_company });
        if (status === 200) {
            setFeedbackMessage("Gamificação atualizada com sucesso");
            setTimeout(() => {
                setFeedbackMessage(null);
                window.location.reload();
            }, 2000)
        }
    }

    useEffect(() => {
        getGamification()
    }, []);

    return (
        <div className={classes.settingTabContainer}>
            <div className={classes.rewardAdminTitle}>
                <SettingsOutlined />
                <h5>Configurações</h5>
            </div>

            {
                feedbackMessage && (
                    <div className={classes.modal}>
                        <div className={classes.feedbackMessageContainer}>
                            <p>{feedbackMessage}</p>
                        </div>
                    </div>
                )
            }

            <div className={classes.settingsInputDataContainer}>
                <div className={classes.settingsInputData}>
                    <label className={classes.settingsInputDataLabel}>Nome da moeda:</label>
                    <input
                        className={classes.settingsInput}
                        type='text'
                        value={coinName}
                        onChange={(event) => setCoinName(event.target.value)}
                    />
                </div>
                <div className={classes.settingsInputData}>
                    <label className={classes.settingsInputDataLabel}>Peso / Score:</label>
                    <input
                        className={classes.settingsInput}
                        type='text'
                        value={scoreCoin}
                        onChange={(event) => setScoreCoin(event.target.value)}
                    />
                </div>
                <button className={classes.settingsButton} onClick={handleUpdateGamification}>Salvar</button>
            </div>
        </div>
    )
}

export default GamificationSettings;