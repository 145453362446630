import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import MaterialTable from 'material-table';
import {
    Grid,
    Button,
} from '@material-ui/core';

import { useStyles } from '../../styles';

import { localizationTable, options } from 'constants/table';
import GamificationData from 'services/dataService/GamificationData';
import { useSelector } from 'react-redux';
import { FilesData } from 'services/dataService';

export default () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [name, setName] = useState("");
    const [stock, setStock] = useState(null);
    const [cost, setCost] = useState(null);
    const [description, setDescription] = useState("");
    const [feedbackMessage, setFeedbackMessage] = useState(null);
    const [selectedRewardId, setSelectedRewardId] = useState(null);
    const [photo, setPhoto] = useState(null);

    const tableRef = useRef();
    const classes = useStyles();

    const { t } = useTranslation([
        'label',
        'toastify',
        'tables',
        'form',
        'button',
        'validation',
    ]);

    const { id, id_company } = useSelector(
        (state) => state.auth
    );

    async function handleAddReward(event, data) {
        console.log('handleAddReward!');

        event.preventDefault();

        const { name, cost, stock, description, id_company } = data;

        const responseFile = await FilesData.addFile(photo, 'Reward');

        const rewardResponse = await GamificationData.createReward({
            name: name,
            cost: cost,
            stock: stock,
            description: description,
            id_company: id_company,
            id_file: responseFile.data.id
        })

        if (rewardResponse.status === 200) {
            setIsModalOpen(!isModalOpen);
            setFeedbackMessage("Recompensa adicionada com sucesso");
            setTimeout(() => {
                setFeedbackMessage(null);
            }, 2000)
            tableRef.current && tableRef.current.onQueryChange(); // Atualiza a tabela após a edição
        }

        if (rewardResponse.status !== 200) {
            setFeedbackMessage("Erro ao adicionar recompensa");
            setTimeout(() => {
                setFeedbackMessage(null);
            }, 2000)
            tableRef.current && tableRef.current.onQueryChange(); // Atualiza a tabela após a edição
        }

    }

    async function handleEditReward(event, data) {
        event.preventDefault();
        console.log('handleEditReward!');

        const responseFile = await FilesData.addFile(photo, 'Reward');


        try {
            const rewardResponse = await GamificationData.updateReward({
                id_reward: selectedRewardId,
                data: {
                    name: data.name,
                    cost: data.cost,
                    stock: data.stock,
                    description: data.description,
                    id_company: id_company,
                    id_file: responseFile.data.id
                }
            });

            console.log('rewardResponse: ', rewardResponse)

            if (rewardResponse.status === 200) {
                setIsModalOpen(!isModalOpen);
                setFeedbackMessage("Recompensa editada com sucesso");
                setTimeout(() => {
                    setFeedbackMessage(null);
                    // window.location.reload();
                }, 2000)
                tableRef.current && tableRef.current.onQueryChange(); // Atualiza a tabela após a edição
            } else {
                setFeedbackMessage("Erro ao editar recompensa");
            }
        } catch (error) {
            console.error(error);
            toast.error("Erro ao editar recompensa.");
            setTimeout(() => {
                setFeedbackMessage(null);
                // window.location.reload();
            }, 2000)
        }

    }

    async function handleDeleteReward(event, idReward) {
        event.preventDefault();
        console.log('handleDeleteReward!', idReward);
        try {
            const deletedReward = await GamificationData.deleteReward({ id: idReward });

            if (deletedReward.status === 204) {
                setIsModalOpen(!isModalOpen);
                setFeedbackMessage("Recompensa excluída com sucesso!");
                setTimeout(() => {
                    window.location.reload();
                }, 2000);     
            }
            console.log('deletedReward: ', deletedReward);
        } catch (error) {
            console.error(error);
            toast.error("Erro ao deletar recompensa.");
        }
    }

    return (
        <div className='notranslate'>
            {
                feedbackMessage && (
                    <div className={classes.modal}>
                        <div className={classes.feedbackMessageContainer}>
                            <p>{feedbackMessage}</p>
                        </div>
                    </div>
                )
            }
            {
                isModalOpen && (
                    <>
                        <div className={classes.modal}>
                            {
                                modalType === "add" && (
                                    <div className={classes.modalContent}>
                                        <div className={classes.modalHeader}>
                                            <h1 className={classes.modalHeaderTitle}>Adicionar Recompensa</h1>
                                            <div className={classes.modalClose}>
                                                <a onClick={() => setIsModalOpen(!isModalOpen)}>
                                                    <span className={classes.modalCloseIcon}>✖</span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className={classes.modalRewardContainer}>
                                            <form className={classes.modalForm} onSubmit={(event) => handleAddReward(event, { name, cost, stock, description, id_company })}>
                                                <input type='text' onChange={(e) => setName(e.target.value)} className={classes.modalFormInput} placeholder='Nome' />
                                                <input type='number' onChange={(e) => setStock(e.target.value)} className={classes.modalFormInput} placeholder='Estoque' />
                                                <input type='number' onChange={(e) => setCost(e.target.value)} className={classes.modalFormInput} placeholder='Custo' />
                                                <textarea type='text' onChange={(e) => setDescription(e.target.value)} className={classes.modalFormInput} placeholder='Descrição' />
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(e) => setPhoto(e.target.files[0])}
                                                    className={classes.modalFormInput}
                                                />
                                                 <span style={{fontSize: '12px'}}>Tamanho sugerido de imagem: 500x281</span>
                                            
                                                <button type='submit' className={classes.modalFormButton}>Adicionar</button>
                                            </form>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                modalType === "edit" && (
                                    <div className={classes.modalContent}>
                                        <div className={classes.modalHeader}>
                                            <h1 className={classes.modalHeaderTitle}>Editar Recompensa</h1>
                                            <div className={classes.modalClose}>
                                                <a onClick={() => setIsModalOpen(!isModalOpen)}>
                                                    <span className={classes.modalCloseIcon}>✖</span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className={classes.modalRewardContainer}>
                                            <form className={classes.modalForm} onSubmit={(event) => handleEditReward(event, { name, cost, stock, description, id_company })}>
                                                <input type='text' value={name} onChange={(e) => setName(e.target.value)} className={classes.modalFormInput} placeholder='Nome' />
                                                <input type='number' value={stock} onChange={(e) => setStock(e.target.value)} className={classes.modalFormInput} placeholder='Stock' />
                                                <input type='number' value={cost} onChange={(e) => setCost(e.target.value)} className={classes.modalFormInput} placeholder='Custo' />
                                                <textarea type='text' value={description} onChange={(e) => setDescription(e.target.value)} className={classes.modalFormInput} placeholder='Descrição' />
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(e) => setPhoto(e.target.files[0])}
                                                    className={classes.modalFormInput}
                                                />
                                                <span style={{fontSize: '12px'}}>Tamanho sugerido de imagem: 500x281</span>

                                                <button type='submit' className={classes.modalFormButton}>Editar recompensa</button>
                                            </form>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                modalType === "delete" && (
                                    <div className={classes.modalContent}>
                                        <div className={classes.modalHeader}>
                                            <h1 className={classes.modalDeleteHeaderTitle}>Remover Recompensa</h1>
                                            <div className={classes.modalClose}>
                                                <a onClick={() => setIsModalOpen(!isModalOpen)}>
                                                    <span className={classes.modalCloseIcon}>✖</span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className={classes.modalDeleteContainer}>
                                            <p>Deseja apagar a recompensa <strong>{name}</strong>?</p>
                                            <div className={classes.modalRemoveRewardAction}>
                                                <a className={classes.modalRemoveRewardActionButtonActive} onClick={(event) => handleDeleteReward(event, selectedRewardId)}>Sim</a>
                                                <a className={classes.modalRemoveRewardActionButtonInactive} onClick={() => setIsModalOpen(!isModalOpen)}>Não</a>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </>
                )
            }
            <Grid container spacing={3} style={{ marginTop: 20 }}>
                <Grid
                    item
                    className={classes.actions}>
                    <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        onClick={() => {
                            setIsModalOpen(!isModalOpen);
                            setModalType('add');
                        }}
                    >
                        Adicionar Recompensa
                    </Button>
                </Grid>
            </Grid>
            <MaterialTable
                style={{ marginTop: 30 }}
                title={`${t('Recompensas')}`}
                tableRef={tableRef}
                columns={[
                    {
                        title: `${t('Nome')}`,
                        field: `name`,
                        sorting: false,
                        render: (rowData) => rowData.name,
                    },
                    {
                        title: `${t('Custo')}`,
                        sorting: false,
                        field: 'cost',
                        render: (rowData) => rowData.cost,
                    },
                    {
                        title: `${t('Stock')}`,
                        field: 'stock',
                        sorting: false,
                        render: (rowData) => rowData.stock,
                    },
                    {
                        title: `${t('Descrição')}`,
                        field: 'description',
                        sorting: false,
                        render: (rowData) =>
                            rowData.description,
                    },
                ]}
                data={async (query) => {
                    try {
                        const rewardsResponse = await GamificationData.getAllRewards({
                            id_company
                        });
                        const { data: { allRewards } } = rewardsResponse;
                        setIsLoading(false);

                        return {
                            data: allRewards,
                            page: rewardsResponse.data.currentPage - 1,
                            totalCount: rewardsResponse.data.totalCount,
                        };
                    } catch (err) {
                        if (err.response?.status === 403) {
                            toast.error(t('toastify:commons.toast_error_403'));
                        } else {
                            toast.error(t('toastify:commons.toast_error_api'));
                        }
                        setIsLoading(false);

                        return { data: [] };
                    }
                }}
                components={{
                    search: true,
                    title: false,
                }}
                actions={[
                    {
                        icon: 'edit',
                        tooltip: `Editar`,
                        position: 'row',
                        onClick: (event, rowData) => {
                            event.preventDefault();
                            setIsModalOpen(!isModalOpen);
                            setModalType("edit");
                            setName(rowData.name);
                            setCost(rowData.cost);
                            setStock(rowData.stock);
                            setDescription(rowData.description);
                            rowData.id && setSelectedRewardId(rowData.id);
                        },
                        iconProps: { color: 'primary' },
                    },
                    {
                        icon: 'delete',
                        tooltip: `Remover`,
                        position: 'row',
                        onClick: (event, rowData) => {
                            event.preventDefault();
                            setIsModalOpen(!isModalOpen);
                            setModalType("delete");
                            setName(rowData.name);
                            setCost(rowData.cost);
                            setStock(rowData.stock);
                            setDescription(rowData.description);
                            rowData.id && setSelectedRewardId(rowData.id);
                        },
                        iconProps: { color: 'primary' },
                    },
                ]}
                isLoading={isLoading}
                localization={localizationTable(t)}
                options={options}
            />
        </div>

    );
};
