import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  InputLabel,
  Switch,
} from '@material-ui/core';

import { Save } from '@material-ui/icons';

import { toast } from 'react-toastify';

import { useStyles } from './styles';
import { CategoryData } from 'services/dataService';

export default ({ className, isModalInsert, idCategory, ...rest }) => {
  const [status, setStatus] = useState(true);
  const [loading, setLoading] = useState(true);

  const { id } = useParams();

  const { register, handleSubmit, errors, setValue } = useForm();
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation([
    'title',
    'form',
    'button',
    'validation',
    'toastify',
  ]);

  useEffect(() => {
    if (id && !isModalInsert) {
      const fetchData = async () => {
        try {
          const response = await CategoryData.getCategoryById(id);

          const { name, status } = response.data;

          setStatus(status);
          
          setValue([{ name }]);
        } catch (err) {
          history.push('/categories');
          if (err.response?.status === 403) {
            toast.error(t('toastify:commons.toast_error_403'));
          } else {
            toast.error(t('toastify:commons.toast_error_api'));
          }

        }
      };

      fetchData();
    }

    setLoading(false);
  }, [id, t]);

  const onSubmit = async (data) => {
    try {
      if (id) {
        await CategoryData.updateCategory(idCategory ? idCategory : id, data);
        toast.success(t('toastify:commons.toast_update'));
      } else {
        await CategoryData.addCategory(data);
        toast.success(t('toastify:commons.toast_success'));
      }

      if (!isModalInsert) history.push('/categories');
      if (isModalInsert) isModalInsert(false);
      setValue([{ name: '' }]);
    } catch (error) {
      if (error.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        const toastMessage = id ? 'toast_update_error' : 'toast_error'
        toast.error(t(`toastify:commons.${toastMessage}`));
      }
    }
  };

  const handleStatusChange = () => {
    setStatus((prev) => !prev);
  };

  const titlePage = id
    ? t('title:Library.title_edit')
    : t('title:Library.title_create');

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title={titlePage} />
      <Divider />
      {!loading && (
        <form
          {...rest}
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit(onSubmit)}
        >
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={t('form:Library.input_title')}
                  helperText={
                    errors.name?.type === 'required' &&
                    t('validation:commons.validation_required', {
                      field: t('form:Library.input_title'),
                    })
                  }
                  name="name"
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({ required: true })}
                  error={!!errors.name}
                />
              </Grid>

              <Grid item md={6} xs={12} style={{ display: 'none' }}>
                <InputLabel id="status">
                  {t('form:commons.input_status')}
                </InputLabel>
                <Switch
                  id="status"
                  checked={status}
                  onChange={handleStatusChange}
                  color="primary"
                  inputRef={register}
                  name="status"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </Grid>
            </Grid>
          </CardContent>

          <Divider />
          <CardActions className={classes.actions}>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              size="large"
            >
              {t('button:commons.btn_save')}
              <Save />
            </Button>
          </CardActions>
        </form>
      )}
    </Card>
  );
};
