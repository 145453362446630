import { makeStyles } from '@material-ui/styles';
import { color } from '@mui/system';

export const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    display: 'flex',
    justifyContent: 'center',
    margin: '5%',
  },
  modal: {
    position: 'absolute',
    background: "#161616ca",
    width: "100vw",
    height: "100vh",
    top: 0,
    left: 0,
    zIndex: 9999,
    padding: 50,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"
  },
  dialogContent: {
    padding: '20px',
},
  modalContent: {
    background: "#FFFFFF",
    padding: 20,
    borderRadius: 10
  },
  modalContainer: {
    marginTop: 50,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    minWidth: "250px",
  },
  modalStatsContainer: {
    marginTop: 30,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "60vw",
    maxHeight: "70vh",
    overflowY: "scroll",
    padding: "30px 0"
  },
  modalStatsTableHeader: {
    marginBottom: 50,
    padding: "10px 0",
    fontSize: ".9rem",
    minHeight: 50
  },
  modalStatsTableRow: {
    '& td': {
      padding: "10px 0",
      fontSize: ".9rem"
    },
  },
  modalForm: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    width: "100%"
  },
  modalFormInput: {
    padding: 10,
    width: "100%"
  },
  modalFormInput: {
    padding: 10
  },
  modalHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  modalHeaderTitle: {
    fontSize: "12px"
  },
  modalClose: {
    background: theme.palette.primary.main,
    padding: "5px",
    width: "20px",
    height: "20px",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  modalCloseIcon: {
    color: theme.palette.background.paper,
    padding: "2px"
  },
  modalFormButton: {
    width: "100%",
    height: "100%",
    padding: 10,
    outline: "none",
    borderColor: theme.palette.primary.main,
    textDecoration: "none",
    background: theme.palette.primary.main,
    color: theme.palette.background.paper,
    marginTop: '5px'
  },
  feedbackMessageContainer: {
    background: "#FFF",
    padding: "5%"
  },
  feedbackMessageRewardsContainer: {
    background: "#FFF",
    padding: "5%",
    fontWeight: 500,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 10
  },
  modalStatsTable: {
    width: "100%",
    textAlign: "left",
    border: "none"
  },
  rowStatusTypeCredit: {
    width: "15px",
    height: "15px",
    borderRadius: "10px",
    background: "green"
  },
  rowStatusTypeDebit: {
    width: "15px",
    height: "15px",
    borderRadius: "10px",
    background: "red"
  },
  scoreViewContainer: {
    background: "#fafafa",
    padding: 40
  },
  scoreViewHeader: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "4rem"
  },
  scoreHeaderButtonContainer: {
    cursor: "pointer",
    opacity: .7,
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    alignItems: "center",
    background: "#FFFFFF",
    fontSize: "1.1em",
    padding: "1rem",
    borderRadius: 10,
    fontWeight: "bold",
    boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
    '& a': {
      color: "#111"
    },
    '&:hover': {
      opacity: 1
    }
  },
  vitrineContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "2rem",
    flexWrap: "wrap"
  },
  rewardBoxContentBottom: {
    display: "flex",
    flexDirection: "column",
    gap: ".5rem",
  },
  rewardBoxCoins: {
    fontWeight: 500,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: ".5rem",
    textAlign: "center"
  },
  rewardBoxContent: {
    padding: "20px 10px",
    display: "flex",
    flexDirection: "column",
    gap: ".5rem",
    textAlign: "center"
  },
  rewardTitle: {
    margin: "20px 0"
  },
  rewardBoxButton: {
    marginTop: 10,
    background: theme.palette.primary.main,
    color: "#FFFFFF",
    padding: 10,
    borderRadius: 10,
    textAlign: "center",
    cursor: "pointer"
  },
  rewardBox: {
    width: "100%",
    background: "#FFFFFF",
    boxShadow: "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
    borderRadius: "10px"
  },
  rewardBoxImage: {
    background: "#eeeeee",
    maxWidth: "100%",
    height: 200,
    borderRadius: "10px 10px 0 0"
  },
  rewardBoxImageElement: {
    maxWidth: "100%",
    height: "100%"
  },
  scoreViewContent: {
    marginTop: "40px"
  },
  settingTabContainer: {
    padding: "2rem"
  },
  settingsInputDataContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    marginTop: 50,
    maxWidth: "40%"
  },
  settingsInputData: {
    display: "flex",
    flexDirection: "column",
    gap: 5
  },
  settingsInput: {
    padding: 5,
    minHeight: 40
  },
  settingsButton: {
    background: "#176AE6",
    padding: 5,
    minHeight: 40,
    border: "none",
    color: "#FFFFFF",
    cursor: "pointer",
    fontWeight: 600
  },
  settingsInputDataLabel: {
    fontSize: ".9rem"
  },
  rewardAdminContainer: {
    padding: 20
  },
  rewardAdminTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "1rem"
  },
  modalRewardContainer: {
    marginTop: 50,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    minWidth: "400px",
  },
  modalDeleteContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 20
  },
  modalRemoveRewardAction: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "1rem",
  },
  modalDeleteHeaderTitle: {
    marginBottom: 30,
    fontSize: "12px",
  },
  modalRemoveRewardActionButtonActive: {
    background: theme.palette.primary.main,
    color: "#FFFFFF",
    padding: 5,
    cursor: "pointer"
  },
  modalRemoveRewardActionButtonInactive: {
    padding: 5,
    cursor: "pointer"
  },
  rankingTabContainer: {
    padding: "2rem",
    display: "flex",
    flexDirection: "column",
  },
  rankingAdminTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "1rem"
  },
  reportsTabContainer: {
    padding: "2rem",
    display: "flex",
    flexDirection: "column",
  },
  reportsAdminTitle: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
    padding: "1rem",
    alignItems: "center"
  },
  [theme.breakpoints.down('sm')]: {
    selectContainer: {
      flexWrap: 'wrap',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
