import React, { useState, useRef, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Autocomplete } from '@material-ui/lab';
import Dialog from '@material-ui/core/Dialog';
import TeamData from 'services/dataService/TeamData';
import { localizationTable, options } from 'constants/table';
import {
  statusPeriodFinished,
  statusUser,
} from 'constants/status';
import {
  CardContent,
  Grid,
  Button,
  Typography,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Paper
} from '@material-ui/core';
import { exportToXlsx, exportToXlsxDisk } from 'helpers/reportExport';
import { VerticalAlignBottom, Search } from '@material-ui/icons';
import { useStyles } from './styles';
import { ReportCompanyData } from 'services/dataService';
import { toast } from 'react-toastify';
//import NotificationData from 'services/dataService/NotificationData';
import Tooltip from "@material-ui/core/Tooltip";

export default ({ fields }) => {
  const [status, setStatus] = useState(1);
  const [disableButton, setDisableButton] = React.useState(true);
  const [periodBoolean, setPeriodBoolean] = React.useState(2)
  const [periodFinished, setPeriodFinished] = useState(0);
  const [disableDescription, setDisableDescription] = useState(true);
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [filter, setFilter] = useState('');
  const [disable, setDisable] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const history = useHistory();


  const handlePeriodFinishedChange = (value) => {
    setPeriodFinished(value);
  }

  const columnsField = fields.map(field => ({
    ...field,
    sorting: false
  }));


  const handleChange = (value) => {
    setStatus(value);
    handleFetch(value);
  }

  const { t } = useTranslation([
    'label',
    'toastify',
    'tables',
    'form',
    'button',
    'validation',
  ]);
  const { register, handleSubmit, control } = useForm();
  const tableRef = useRef();

  const classes = useStyles();


  const onSubmit = (data) => {

    setFilter(data.filter);
    setStatus(data.status);

    setDisableButton(true);

    tableRef.current.state.query.page = 0;
    tableRef.current.onQueryChange();
  };



  const exportActionType = {
    excel: (col, responseData, title) => {
      exportToXlsxDisk(col, responseData, title);
    },
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFetch = async (status, periodFinished) => {
    try {
      const response = await ReportCompanyData.getAcademic({
        filter,
        status,
        periodFinished,
        isExport: true,
      });

      return response.data;
    } catch (err) {
      toast.error(
        "Não foi possível buscar os dados do relatório, tente novamente mais tarde."
      );
      return [];
    }
  };

  const handleFetchToXlsx = async (status, periodFinished, col) => {
    const response = await ReportCompanyData.getAcademicToXlsx({
      filter,
      status,
      periodFinished,
      isExport: true,
      columns: JSON.stringify(col),
    });
    return [];
  };

  const existeRelatorioParaBaixar = async () => {

    const res = localStorage.getItem("RelatorioAcademicoDownload");

    if (res == "1") {
      setDisable(true);
    }
  };

  const customExport = useCallback(async (type) => {
    try {
      setDisableDescription(false);
      setDisable(true);
  
      localStorage.setItem("RelatorioAcademicoDownload", "1");
  
      toast.info(t('toastify:commons.toast_generatereport'));  
      const filteredColumns = tableRef.current.props.columns.map(column => {
        const { tableData, ...rest } = column;
        return rest;
      });
  
      const responseData = handleFetchToXlsx(status, periodFinished, filteredColumns);
  
      setShowConfirmation(true);
  
    } finally {
      handleClose();
    }
  }, [status, periodFinished]);
  

  const handleClickConfirmSave = () => {
    setShowConfirmation(false);
    window.location.reload()
  };

  const handleNotificationClick = () => {
    history.push('/notification');
  };
  return (
    <>
      <div className='notranslate'>
        <Dialog
          open={showConfirmation}
          onClose={() => setShowConfirmation(false)}
        >
          <DialogTitle style={{ fontSize: '22px', textAlign: 'center' }}>⚠️ Atenção</DialogTitle>
          <DialogContent style={{ textAlign: 'center' }}>
            <Typography variant="subtitle1" gutterBottom>
              Em alguns instantes o seu Relatório Acadêmico estará disponível em Notificações.
            </Typography>
            <Typography variant="subtitle1">
              Basta clicar em <b>Notificações</b> &rarr; <b>Ver todas</b>
            </Typography>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleClickConfirmSave()}
              style={{ padding: '10px 50px' }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <MaterialTable
          title={
            disableDescription ? (
              <div style={{ marginTop: 15, marginBottom: 15 }}>
                <Typography variant="h6">
                  {`${t('tables:reports.title_reports_academic')}`}
                </Typography>
                <Typography variant="subtitle2" style={{ fontSize: 12 }}>
                  Use os filtros de pesquisa para localizar estudantes: <br />
                  por nome, CPF, e-mail, status ou ano de conclusão do curso.
                </Typography>
              </div>
            ) : (
              `${t('tables:reports.title_reports_academic')}`
            )
          }
          tableRef={tableRef}
          columns={
            columnsField || [
              {
                title: `${t('tables:reports.academic.teams_column')}`,
                field: `name`,
                render: (rowData) => rowData.name,
              },
              {
                title: `${t('tables:reports.academic.users_column')}`,
                field: `count_students`,
                render: (rowData) => rowData.student_name,
              },
              {
                title: `${t('tables:reports.academic.email_column')}`,
                field: `student_email`,
                render: (rowData) => rowData.student_email,
              },
              {
                title: `${t('tables:reports.academic.created_at')}`,
                field: `created_at`,
                render: (rowData) => rowData.created_at,
              },
              {
                title: `${t('tables:reports.academic.cpf_column')}`,
                field: `student_cpf`,
                render: (rowData) => rowData.student_cpf,
              },
              {
                title: `${t('tables:reports.academic.courses_column')}`,
                sorting: false,
                field: `course_name`,
                render: (rowData) => rowData.course_name,
              },
              {
                title: `${t('tables:reports.academic.trails_column')}`,
                field: `trail_name`,
                render: (rowData) => rowData.trail_name,
              },
              {
                title: `${t('tables:reports.academic.certificate_date_column')}`,
                field: `count_trails`,
                render: (rowData) => rowData.count_trails,
              },
              {
                title: `${t('tables:reports.academic.last_login_column')}`,
                field: `last_course_access`,
                render: (rowData) => rowData.last_course_access,
              },
              {
                title: `${t('tables:reports.academic.grade_column')}`,
                field: `grade`,
              },
              {
                title: `${t('tables:reports.academic.finished_column')}`,
                field: `count_lessons_finished`,
                render: (rowData) => rowData.count_lessons_finished,
              },
              {
                title: `${t('tables:reports.academic.progress_column')}`,
                field: `percent_progress`,
                render: (rowData) => rowData.percent_progress,
              },
            ]
          }
          data={async (query) => {
            try {
              const response = await ReportCompanyData.getAcademic({
                page: query.page + 1,
                limit: query.pageSize,
                filter,
                status,
                periodFinished,
              });

              existeRelatorioParaBaixar();

              let { academic, currentPage, totalCount } = response.data;

              academic = academic?.map((academicElement) => {
                let customFields = {};


                if (academicElement?.custom_field_values) {
                  const formattedCustomFieldValue = JSON.parse(
                    academicElement?.custom_field_values
                  );

                  for (const field of formattedCustomFieldValue) {
                    customFields[field?.fieldName] = field?.fieldValue;
                  }
                }

                return { ...academicElement, ...customFields };
              });

              return {
                data: academic,
                page: currentPage - 1,
                totalCount,
              };
            } catch (err) {
              toast.error(t('toastify:commons.toast_error_api'));
              return { data: [] };
            }
          }}
          components={{
            search: true,
            title: true,
            Toolbar: (props) => (
              <div style={{ position: 'relative' }}>
                {disable && (
                  <div className={classes.message}>
                    <Typography variant="subtitle2" style={{ fontSize: 12 }}>
                      Acesse <a href="#" onClick={handleNotificationClick}>notificações</a> para fazer o download <br />
                      do seu relatório acadêmico.
                    </Typography>
                  </div>
                )}
                <MTableToolbar {...props} />
                <form onSubmit={handleSubmit(onSubmit)}>
                  <CardContent>
                    <Grid container spacing={1} md={12}>
                      <Grid item md={4} xs={12} >
                        <TextField
                          fullWidth
                          label={t('form:commons.input_search')}
                          name="filter"
                          type="text"
                          variant="outlined"
                          defaultValue={filter || ''}
                          inputRef={register}
                        />
                      </Grid>
                      <Grid item md={2} xs={12}>
                        <FormControl style={{ minWidth: 130 }}>
                          <InputLabel id="status">
                            {t("form:commons.input_status")}
                          </InputLabel>
                          <Controller
                            as={
                              <Select>
                                <MenuItem value={1}>{t(statusUser[1]?.title)}</MenuItem>
                                <MenuItem value={2}>{t(statusUser[4]?.title)}</MenuItem>
                              </Select>
                            }
                            name="status"
                            control={control}
                            onChange={([event]) => handleChange(event.target.value)}
                            defaultValue={status}
                            value={status}
                          />
                        </FormControl>
                      </Grid>


                      <Grid item md={2} xs={12}>

                        <Grid item md={2} xs={12}>
                          <FormControl style={{ minWidth: 130 }}>
                            <InputLabel id="status3">
                              {t("form:commons.input_period_finished")}
                            </InputLabel>
                            <Controller
                              as={
                                <Select>
                                  <MenuItem value={1}>{t(statusPeriodFinished[1]?.title)}</MenuItem>
                                  <MenuItem value={2}>{t(statusPeriodFinished[2]?.title)}</MenuItem>
                                  <MenuItem value={3}>{t(statusPeriodFinished[3]?.title)}</MenuItem>
                                  <MenuItem value={5}>{t(statusPeriodFinished[5]?.title)}</MenuItem>
                                  <MenuItem value={6}>{t(statusPeriodFinished[6]?.title)}</MenuItem>
                                  <MenuItem value={4}>{t(statusPeriodFinished[4]?.title)}</MenuItem>
                                </Select>
                              }
                              name="periodFinished"
                              control={control}
                              onChange={([event]) => handlePeriodFinishedChange(event.target.value)}
                              defaultValue={periodFinished}
                              value={periodFinished}
                            />
                          </FormControl>
                        </Grid>

                      </Grid>


                      <Grid item md={4} xs={12} className={classes.actions}>
                        <Button
                          color="primary"
                          variant="contained"
                          size="large"
                          type="submit"
                        >
                          <Search className={classes.icon} />
                          {t('button:User_solictation.btn_advanced_filter')}
                        </Button>
                      </Grid>
                    </Grid>

                  </CardContent>
                </form>
              </div>
            ),
          }}
          localization={localizationTable(t)}
          actions={[
            {
              icon: () => (
                disableButton && (
                  <>
                    <Tooltip title={"Somente após o download de um relatório solicitado, é possível solicitar novamente."}>
                      <span>
                        <Button disabled={disable}>
                          <VerticalAlignBottom
                            handleFetch={() => handleFetch(status, periodFinished)}
                            onClick={() => customExport("excel")}
                            tableRef={tableRef}
                          />
                        </Button>
                      </span>
                    </Tooltip>
                  </>
                )
              ),
              isFreeAction: true,
            },
          ]}

          options={{
            ...options,
            exportButton: false,
            doubleHorizontalScroll: 'true',
            // maxBodyHeight: 530,
          }}
        />
      </div>
    </>
  );
};
