import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Typography,
} from '@material-ui/core';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import { Email } from '@material-ui/icons';

import { toast } from 'react-toastify';

import { useStyles } from './styles';
import AttendanceData from 'services/dataService/AttendanceData';
import { PhoneMaskCustom } from 'components/PhoneMaskCustom';

export default ({ className, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState();

  const {
    register,
    handleSubmit,
    errors,
    reset,
  } = useForm();

  const { id_company } = useSelector((state) => state.auth);

  const { t } = useTranslation([
    'title',
    'form',
    'button',
    'validation',
    'toastify',
  ]);
  const classes = useStyles();

  const onSubmit = useCallback(
    async (data) => {
      const messageText = message
        ? draftToHtml(convertToRaw(message.getCurrentContent()))
        : '';

      data.message = messageText;
      data.id_company = id_company;
      try {
        await AttendanceData.addAttendance(data);

        toast.success(t('toastify:commons.toast_success'));
        reset();
        setMessage()
      } catch (error) {
        if (error.response?.status === 403) {
          toast.error(t('toastify:commons.toast_error_403'));
        } else {
          toast.error(t('toastify:commons.toast_error_api'));
        }  
      }
    },
    [t, message]
  );

  useEffect(() => {
    setLoading(false);
  }, []);

  const titlePage = t('title:Attendance.title_create');

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title={titlePage} />
      <Divider />
      {!loading && (
        <form
          {...rest}
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit(onSubmit)}
        >
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={t('form:Attendance.input_name')}
                  helperText={
                    errors.name?.type === 'required' &&
                    t('validation:commons.validation_required', {
                      field: t('form:Attendance.input_name'),
                    })
                  }
                  name="name"
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({ required: true })}
                  error={!!errors.name}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={t('form:Attendance.input_subject')}
                  helperText={
                    errors.subject?.type === 'required' &&
                    t('validation:commons.validation_required', {
                      field: t('form:Attendance.input_subject'),
                    })
                  }
                  name="subject"
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({ required: true })}
                  error={!!errors.subject}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={t('form:Attendance.input_email')}
                  helperText={
                    (errors.email?.type === 'required' &&
                      t('validation:commons.validation_required', {
                        field: t('form:Attendance.input_email'),
                      })) ||
                    (errors.email?.type === 'pattern' &&
                      t('validation:commons.validation_cpf', {
                        field: t('form:Attendance.input_email'),
                      }))
                  }
                  name="email"
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    },
                  })}
                  error={!!errors.email}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={t('form:Attendance.input_cellphone')}
                  helperText={
                    errors.phone?.type === 'required' &&
                    t('validation:commons.validation_required', {
                      field: t('form:Attendance.input_cellphone'),
                    })
                  }
                  name="phone"
                  type="text"
                  variant="outlined"
                  InputProps={{
                    inputComponent: PhoneMaskCustom,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({ required: true })}
                  error={!!errors.phone}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} className={classes.columnGrid}>
                <Typography color="textSecondary" gutterBottom variant="body2">
                  {t('form:Attendance.input_message')}
                </Typography>
                <Editor
                  readOnly={false}
                  editorStyle={{
                    border: '1px solid #C0C0C0',
                    borderRadius: '4px',
                    height: '17rem',
                  }}
                  editorState={message}
                  toolbarClassName="rdw-storybook-toolbar"
                  wrapperClassName="rdw-storybook-wrapper"
                  editorClassName="rdw-storybook-editor"
                  onEditorStateChange={(vl) => setMessage(vl)}
                  toolbar={{
                    options: ['inline', 'list', 'link', 'remove', 'history'],
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>

          <Divider />
          <CardActions className={classes.actions}>
            <Button color="primary" size="large" type="reset" onClick={reset}>
              {t('button:commons.btn_clear')}
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              size="large"
            >
              {t('button:commons.btn_send')}
              <Email />
            </Button>
          </CardActions>
        </form>
      )}
    </Card>
  );
};
