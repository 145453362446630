import React, { useState, useCallback, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MaterialTable, { MTableToolbar } from 'material-table';
import CircleIcon from '@material-ui/icons/Brightness1';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { localizationTable, options } from 'constants/table';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';

import {
  CardContent,
  Grid,
  Button,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core';

import { useStyles } from './styles';

import { ContractData } from 'services/dataService';
import { statusContracts } from 'constants/status';
import { toast } from 'react-toastify';
import { Search } from '@material-ui/icons';

export default () => {
  const [status, setStatus] = useState('true');
  const [version, setVersion] = useState('');

  const history = useHistory();
  const { t } = useTranslation([
    'label',
    'toastify',
    'tables',
    'form',
    'button',
    'validation',
  ]);
  const { register, handleSubmit, control } = useForm();
  const tableRef = useRef();

  const classes = useStyles();

  const handleEdit = useCallback(
    ({ id }) => {
      history.push(`/contracts/${id}/edit`);
    },
    [history]
  );

  const onSubmit = (data) => {
    setVersion(data.version);
    setStatus(data.status);

    tableRef.current.state.query.page = 0;
    tableRef.current.onQueryChange();
  };

  return (
    <>
      <div className="notranslate">
        <MaterialTable
          title={`${t('tables:contract.title')}`}
          tableRef={tableRef}
          columns={[
            {
              title: `${t('tables:contract.name_column')}`,
              field: `createdAt`,
              render: (rowData) => moment(rowData.createdAt).format('DD/MM/YYYY HH:mm')
            },
            {
              title: `${t('tables:contract.version')}`,
              field: `version`,
              render: (rowData) => rowData.version
            },
            {
              title: `${t('tables:commons.status_column')}`,
              field: 'status',
              render: (rowData) => (
                <div style={{ display: 'flex' }}>
                  <CircleIcon
                    style={statusContracts[rowData.status]?.style}
                    fontSize="small"
                  />
                  <span style={{ fontWeight: 'bold', fontSize: 14 }}>
                    {t(statusContracts[rowData.status]?.title)}
                  </span>
                </div>
              ),
            },
            {
              title: `${t('tables:commons:actions:label_action')}`,
              field: '',
              render: (rowData) => (
                (rowData.status === true) ?

                  <Tooltip title={t('tables:commons.actions.edit_action')}>
                    <EditIcon
                      color='primary'
                      cursor='pointer'
                      onClick={() => {
                        handleEdit(rowData);
                      }}
                    />
                  </Tooltip>
                  :
                  <Tooltip title={t('tables:commons.actions.view_default')}>
                    <VisibilityIcon
                      color='primary'
                      cursor='pointer'
                      onClick={() => {
                        handleEdit(rowData);
                      }}
                    />
                  </Tooltip>

              ),

            }
          ]}
          data={async (query) => {
            try {
              const response = await ContractData.getContracts({
                page: query.page + 1,
                limit: query.pageSize,
                status,
                version,
              });

              return {
                data: response.data.contracts,
                page: response.data.currentPage - 1,
                totalCount: response.data.totalCount,
              };
            } catch (err) {
              if (err.response?.status === 403) {
                toast.error(t('toastify:commons.toast_error_403'));
              } else {
                toast.error(t('toastify:commons.toast_error_api'));
              }
              return { data: [] };
            }
          }}

          components={{
            search: true,
            title: true,
            Toolbar: (props) => (
              <div>
                <MTableToolbar {...props} />
                <form onSubmit={handleSubmit(onSubmit)}>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          label={t('form:commons.version')}
                          name="version"
                          type="text"
                          variant="outlined"
                          defaultValue={version || ''}
                          inputRef={register}
                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <FormControl style={{ minWidth: 100 }}>
                          <InputLabel id="status">
                            {t('form:User_solicitation.input_status_select')}
                          </InputLabel>

                          <Controller
                            as={
                              <Select>
                                <MenuItem value="true">
                                  {t(statusContracts['true']?.title)}
                                </MenuItem>
                                <MenuItem value="false">
                                  {t(statusContracts['false']?.title)}
                                </MenuItem>
                              </Select>
                            }
                            name="status"
                            control={control}
                            defaultValue={status ? status : 'true'}
                            value={status ? status : 'true'}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item md={4} xs={12} className={classes.actions}>
                        <Button
                          color="primary"
                          variant="contained"
                          size="large"
                          type="submit"
                        >
                          {t('button:User_solictation.btn_advanced_filter')}
                          <Search />
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </form>
              </div>
            ),
          }}
          localization={localizationTable(t)}
          options={options}
        />
      </div>

    </>
  );
};
