import { Report } from '@material-ui/icons';
import React from 'react';
import ReportsTable from './components/ReportsTable';
import { useStyles } from '../styles';

const GamificationReports = () => {
    
    const classes = useStyles();

    return (
        <div className={classes.reportsTabContainer}>
            <div className={classes.reportsAdminTitle}>
                <Report />
                <h5>Relatórios</h5>
            </div>
            <ReportsTable />
        </div>
    )
}

export default GamificationReports;