import api from '../api';

class GamificationData {

  getUserById(idUser) {
    api.post('/user-data/', idUser);
  }

  createTransaction(data) {
    if (data.amount && typeof data.amount === 'string') {
      data.amount = parseInt(data.amount, 10); // Converte para inteiro na base decimal
    }
    return api.post('/gamification-transactions', data);
  }

  getTotalCoinsByUserId(userId) {
    return api.post('/user-coins', userId);
  }

  getAllUserCoins() {
    return api.get('/user-coins');
  } 
  
  getRankingUserCoins() {
    return api.get('/user-coins-ranking');
  }

  getAllTransactions(userId) {
    return api.post('/transactions', userId);
  }

  getAllRewards(idCompany) {
    return api.post('/all-rewards', idCompany);
  }

  getRewardById(idReward) {
    const { id } = idReward;
    return api.post(`/get-reward/${id}`);
  }

  createReward(data) {
    return api.post('/rewards', data);
  }

  updateReward(data) {
    return api.put('/rewards', data);
  }

  deleteReward(data) {
    const { id } = data;
    return api.delete(`/rewards/${id}`);
  }

  createRescue(reward) {
    return api.post('/user-rewards', reward);
  }

  getGamificationByIdCompany(idCompany) {
    return api.post('/gamification-by-company', idCompany);
  }

  updateGamification({coin_name, score, id_company}) {
    return api.put('/gamification', {
      coin_name, score, id_company
    });
  }

  getUserRewards() {
    return api.get('/user-rewards');
  }

}

export default new GamificationData();
